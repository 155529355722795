import { useSelector } from 'react-redux';
import { useEffect, useContext } from 'react';
import { UserContext } from 'contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import { isAdmin } from 'utils/common';

const AuthGuard = ({ children }) => {
  const account = useSelector((state) => state.account);
  const { isUserLoaded } = useContext(UserContext);
  const navigate = useNavigate();
  const userIsAdmin = isAdmin();

  useEffect(() => {
    if (isUserLoaded && !account.user) {
      navigate('/login');
      return;
    }

    // 限制仅仅管理员进入
    const userIsAdmin = isAdmin();
    if (!userIsAdmin) {
      navigate('/');
    }
  }, [account, navigate, isUserLoaded]);

  return children;
};

export default AuthGuard;
