import { showError } from './common';
import axios from 'axios';
import { store } from 'store/index';
import { LOGIN } from 'store/actions';
import config from 'config';

export const API = axios.create({
  baseURL: process.env.REACT_APP_SERVER ? process.env.REACT_APP_SERVER : '/'
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('user');
      store.dispatch({ type: LOGIN, payload: null });
      const queryParams = window.location.search; // 仅携带当前页面的查询参数
      if (error.response?.data?.code === 10001) {
      // 用户未完成注册，跳转到绑定手机号页面，并携带查询参数
        window.location.href = `${config.basename}bind_phone${queryParams}`;
      } else {
        window.location.href = `${config.basename}login${queryParams}`;
      }
    }

    if (error.response?.data?.message) {
      error.message = error.response.data.message;
    }

    showError(error);
  }
);
