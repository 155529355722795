import { API } from 'utils/api';
import { useDispatch } from 'react-redux';
import { LOGIN } from 'store/actions';
import { useNavigate, useLocation } from 'react-router';
import { showSuccess } from 'utils/common';
import { parse } from 'tldts';

const getRootDomain = (hostname) => {
  // 解析域名
  const parsed = parse(hostname);

  // 如果解析成功，返回根域名，否则返回原始 hostname
  return parsed.domain || hostname;
};


const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const loginSuccess = (user) => {
    const rootDomain = getRootDomain(window.location.host); // 获取根域名
    const rootUrl = `${window.location.protocol}//${rootDomain}`
    const params = new URLSearchParams(location.search);
    const redirectUri = params.get('redirect_uri');
    console.log(user)
    if (user?.status === 0) {
      // 用户未完成注册，跳转到绑定手机号页面，并携带查询参数
        navigate(`/bind_phone${location.search}`);
    }else if (!redirectUri) {
        // 如果 redirectUri 为空，则跳转到根域名
        window.location.href = rootUrl;
    } else if (redirectUri.startsWith('http')) {
        // 如果是完整 URL，直接跳转
        window.location.href = redirectUri;
    } else {
        // 如果是相对路径，则拼接根域名
        window.location.href = `${rootUrl}${redirectUri.startsWith('/') ? redirectUri : '/' + redirectUri}`;
    }
  }

  const login = async (username, password) => {
    try {
      const res = await API.post(`/api/user/login`, {
        username,
        password
      });
      const { success, message, data } = res.data;
      if (success) {
        localStorage.setItem('user', JSON.stringify(data));
        dispatch({ type: LOGIN, payload: data });
        loginSuccess(data);
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const githubLogin = async (code, state) => {
    try {
      const res = await API.get(`/api/oauth/github?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess('绑定成功！');
        } else {
          dispatch({ type: LOGIN, payload: data });
          localStorage.setItem('user', JSON.stringify(data));
          showSuccess('登录成功！');
          loginSuccess(data);
        }
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const larkLogin = async (code, state) => {
    try {
      const res = await API.get(`/api/oauth/lark?code=${code}&state=${state}`);
      const { success, message, data } = res.data;
      if (success) {
        if (message === 'bind') {
          showSuccess('绑定成功！');
        } else {
          dispatch({ type: LOGIN, payload: data });
          localStorage.setItem('user', JSON.stringify(data));
          showSuccess('登录成功！');
          loginSuccess(data);
        }
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const wechatLogin = async (code) => {
    try {
      const res = await API.get(`/api/oauth/wechat?code=${code}`);
      const { success, message, data } = res.data;
      if (success) {
        dispatch({ type: LOGIN, payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        showSuccess('登录成功！');
        loginSuccess(data);
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

   // 发送验证码
   const sendSms = async (phone) => {
    try {
      const res = await API.get(`/api/sms/register?phone=${phone}`);
      const { success, message } = res.data;
      return { success, message }
    } catch (error) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  // 绑定手机号
  const bindPhone = async (phone, code) => {
    try {
      const res = await API.get(`/api/oauth/phone/bind?phone=${phone}&code=${code}`);
      const { success, message, data } = res.data;
      if (success) {
        dispatch({ type: LOGIN, payload: data });
        localStorage.setItem('user', JSON.stringify(data));
        showSuccess('登录成功！');
        loginSuccess(data);
      }
      return { success, message };
    } catch (err) {
      // 请求失败，设置错误信息
      return { success: false, message: '' };
    }
  };

  const logout = async () => {
    await API.get('/api/user/logout');
    localStorage.removeItem('user');
    dispatch({ type: LOGIN, payload: null });
    navigate('/');
  };

  return { login, logout, githubLogin, wechatLogin, larkLogin, sendSms, bindPhone };
};

export default useLogin;
